<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{card_header_props.header.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
          <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>{{ $t('_common.How_can_I_use_this_form') }}</v-card-title>

          <v-card-text>{{ this.$t('_add_terms._info.content') }}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{ $t('_common.ok') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->

    <v-container class="mx-0 py-0 col-12">

      <v-row>
				<v-col cols="12" sm="12" md="3">
				  <v-card>
            <v-sheet class="px-4 pt-2">
              <v-text-field
                v-model="search"
                :label= "$t('_add_terms.Search_Topic')"
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
            </v-sheet>
            <v-card-text style="height: 416px; overflow: auto;">
              <v-treeview
                :items="treeTopicData"
                :search="search"
                v-model="treeTopicDataModel"
                item-children="Children"
                activatable
                multiple="false"
                selection-type="leaf"
                item-key="Id"
                color="accent"
                item-text="Title"
                @update:active="onSelectedTopic"
                style="min-width: fit-content;"
              ></v-treeview>
            </v-card-text>
          </v-card>
				</v-col>
				<v-col cols="12" sm="12" md="6">
				  <v-card>
            <!-- CARD 1: ADD WORD -->
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text class="v-flex" style="height: 504px;">
                <v-text-field
                  v-model="keyValue.keyTxt"
                  :label= "this.$t('_common.Text')"
                  name="Text"
                  :rules="validations.termKeyRules"
                ></v-text-field>
                <v-text-field
                  v-model="keyValue.valueTxt"
                  :label= "this.$t('_add_terms.Value')"
                  name="Value"
                  :rules="validations.termValueRules"
                ></v-text-field>
              </v-card-text>
            </v-form>
          </v-card>
				</v-col>
				<v-col cols="12" sm="12" md="3">
				  <v-card>
            <v-sheet class="px-4 pt-2">
              <v-text-field
                v-model="input2"
                v-model.lazy="searchWord"
                :append-outer-icon="'search'"
                @click:append-outer="searchTxt"
                :label= "$t('_add_terms.Search_Word')"
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
                v-on:keyup.enter="searchTxt()"
              ></v-text-field>
            </v-sheet>
            <v-data-table
              :headers="headers"
              :items="indexedItems"
              :server-items-length="totalItems"
              :options.sync="options"
              :page.sync="options.pagination.page"
              :items-per-page.sync="options.pagination.itemsPerPage"
              :loading="tableLoading"
              class="elevation-1"
              :footer-props="{
                'items-per-page-text':$t('_common.Rows_per_page')
              }"
              @update:page="updatePage"
              @update:items-per-page="updatePage"
              @click:row="rowClick"
              :no-results-text="$t('_common.No_Data')"
              :no-data-text="$t('_common.No_Data')"
              height="387"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td>{{props.item.Key}}</td>
                </tr>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="removeTerm(item)" v-on="on" class="mr-2">delete</v-icon>
                  </template>
                  <span>{{ $t('_common.Delete') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="updateTerm(item)" v-on="on">edit</v-icon>
                  </template>
                  <span>{{ $t('_common.Update') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
				</v-col>
			</v-row>


      <v-layout>
        <v-flex xs3 class="my-2">

        </v-flex>
        <v-flex xs6 class="my-2 px-2">

        </v-flex>
        <v-flex xs3 class="my-2">

        </v-flex>
      </v-layout>
    </v-container>
    <v-footer>
      <v-spacer></v-spacer>
      <v-btn @click="clearForm" type="button" class="mr-2" color="warning">{{ $t('_common.Clear') }}</v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="addTerm" v-on="on" color="success">{{ keyValue.id ? $t('_common.Update') : $t('_common.Add')}}</v-btn>
        </template>
        <span>{{ this.$t('_add_terms.Create_a_term') }}</span>
      </v-tooltip>
    </v-footer>
  </v-card>
</template>

<script>
import VueFlashcard from "vue-flashcard";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
require("../../assets/css/neo.css");

export default {
  data() {
    return {
      valid: true,
      validations: {
        termValueRules: this.$goc.validate
          .create()
          .required()
          .min(1)
          .max(100),
        termKeyRules: this.$goc.validate
          .create()
          .required()
          .min(1)
          .max(100),
        termTopicRules: this.$goc.validate
          .create()
          .required()
          .min(1)
      },
      keyValue: {
        id: null,
        keyTxt: "",
        valueTxt: "",
        topic: ""
      },
      searchWord: "",
      take: 0,
      skip: 0,
      topic: 0,
      parentId: 0,
      frontTxt: "This is the front",
      backTxt: "This is the back",
      visible: false,
      searchResults: [],
      headers: [
        {
          text: this.$t("_add_terms.Words"),
          align: "left",
          sortable: false,
          value: "Key"
        },
        {
          text: this.$t("_add_terms.Actions"),
          value: "action",
          sortable: false
        }
      ],

      cardStep: 0,
      treeTopicData: [],
      treeTopicDataModel: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.2
      },
      options: {
        pagination: {
          page: 1, // skip
          itemsPerPage: 10, //take
          pageCount: 0,
          rowsPerPageItems: [10, 20, 30, 40],
          footerProps: { "items-per-page-options": [5, 10, 15, 20] }
        }
      },

      tableLoading: false,
      totalItems: 0,

      search: null,
      card_header_props: {
        header: {
          headLine: this.$t('_add_terms.Add_Terms'),
          subTitle: this.$t('_add_terms.Add_flashcards'),
          icon: "mdi-plus-box"
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false
        }
      },
    };
  },

  methods: {
    clearForm() {
      this.keyValue.id = null;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    removeTerm(item) {
      this.$goc.request.delete(
        this.$enums.API.RemoveTerm,
        res => {
          this.$goc.notify.success({
            title: this.$t('_common.Delete'),
            message: this.$t('_add_terms.Terms_deleted_successfuly')
          });
          this.keyValue.id = null;
          this.searchTxt();
        },
        { TermId: item.Id }
      );
    },
    updateTerm(item) {
      this.isUpdate = true;
      this.keyValue.id = item.Id;
      this.keyValue.keyTxt = item.Key;
      this.keyValue.valueTxt = item.Value;
      this.keyValue.topic = item.Topic;
    },
    addTerm() {
      if (this.validate()) {
        var keyValueTopic = {
          Key: this.keyValue.keyTxt,
          Value: this.keyValue.valueTxt,
          Topic: this.keyValue.topic
        };

        if (this.keyValue.id) { // update
          keyValueTopic.Id = this.keyValue.id;
          this.$goc.request.post(
            this.$enums.API.UpdateTerm,
            keyValueTopic,
            () => {
              this.$goc.notify.success({ title: this.$t('_common.Success'),message: this.$t('_add_terms.Successfully_updated') });
              this.clearForm();
              this.searchTxt();
            }
          );
        } else {
          this.$goc.request.post(this.$enums.API.AddTerm, keyValueTopic, () => {
            this.$goc.notify.success({ title: this.$t("_common.Success"), message: this.$t('_add_terms.Successfully_created') });
            this.clearForm();
            this.searchTxt();
          });
        }
      }
    },
    validate() {
      this.$goc.console.log(this.keyValue.topic);
      if (this.keyValue.topic) return !!this.$refs.form.validate();
      else {
        this.$goc.notify.error({ message: this.$t('_add_terms.Please_select_a_topic') });
        return false;
      }
    },
    searchTxt() {
      if (
        (this.searchWord !== "" && this.searchWord.length >= 3) ||
        this.topic > 0
      ) {
        this.skip = this.options.pagination.page - 1;
        this.take = this.options.pagination.itemsPerPage;

        var searchBody = {
          Text: this.searchWord,
          Take: this.take,
          Skip: this.skip,
          Topic: this.topic
        };
        let me = this;
        this.$goc.request.post(
          this.$enums.API.SearchTerm,
          searchBody,
          response => {
            if (response.Result === undefined) {
              this.visible = false;
              return;
            }

            if (response.Result.Terms.length > 0) {
              this.totalItems = response.Result.TotalItemCount;
              this.searchResults = response.Result.Terms;
              this.visible = true;
            } else {
              this.searchResults = [];
              this.visible = false;
              me.$goc.notify.info({
                title: this.$t('_common.No_Data'),
                message: this.$t('_add_terms.Term_was_not_found')
              });
            }
          }
        );
      }
    },
    rowClick(selectedItem) {
      this.cardStep = selectedItem.index - 1;
    },
    dialogNotify(title, message, notifyColor) {
      this.$vs.notify({
        title: title,
        text: message,
        color: notifyColor
      });
    },

    // validate() {
    //   return this.$refs.form.validate() ? true : false;
    // },
    getTopics() {
      this.$goc.request.get(this.$enums.API.Topic, response => {
        this.treeTopicData = response.Result.Topics;
      });
    },
    onSelectedTopic(item) {
      if (item.length > 0) this.keyValue.topic = item[0];
      else this.keyValue.topic = "";
      // this.selected = this.treeTopicData[item[0]-1].id;
      this.selected = item[0];
      this.treeTopicDataModel = item;
      this.searchWord = "";
      this.skip = 0;
      this.take = 0;
      this.topic = item[0];
      this.searchTxt();
    },
    updatePage(page) {
      this.$goc.console.log(page);
      this.$goc.console.log(this.options);
      this.searchTxt();
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.searchWord != "";
    },
    indexedItems() {
      return this.searchResults.map((item, index) => ({
        index: index + 1,
        ...item
      }));
    }
  },
  mounted: function() {
    this.getTopics();
  },

  components: {
    VueFlashcard,
    VuePerfectScrollbar
  }
};
</script>

<style>
.animated.flipInX.animated {
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  margin: 0;
  border-radius: 7px;
}

.animated.flipInX.animated:hover {
  box-shadow: 1px 4px 25px 7px rgba(0, 0, 0, 0.4);
  margin: 0;
  border-radius: 7px;
}

.animated.flipInX.flashcard {
  height: 100%;
}
.card-header {
  margin-top: 17%;
}
.v-data-table.elevation-1.theme--light:hover {
  cursor: pointer;
}
</style>
